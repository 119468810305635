.ParentBox {
    border: 1px solid black;
    
}

.Title {
    color: snow  ;
    font-family: "chalkduster", cursive;
    font-style: italic;
    font-size: 60px;
    margin-bottom: 20px;
}

.ChildBox {
    border: 1px solid black;
    font-size: 20px;
    padding-left: 30px;
    
}

.BoxTitle {
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    padding-top: 10px;
    padding-left: 30px;
}

.BabyBox {
    border: 2px solid black;
    border-radius: 30px;
    margin-top: 10px;
    margin-left: 35px;
    padding-left: 60px;
    padding-top: 2px;
    padding-bottom: 5px;
    /*padding: 4px;*/
    width: 600px;
    height: 30px; 
    font-size: 15px;
}


.CostBabyBox{
    border: 2px solid black;
    border-radius: 30px;
    margin-top: 10px;
    margin-left: 10px;
    padding-left: 20px;
    width: 150px;
    height: 30px; 
    font-size: 12px;
    float:left;
    font-size: 1000px !important;
}

.AddField{
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 45px;
    color:blue;
}