.ProfileDropdownOption {
    /* position: absolute;
    display: block;
    height:20px; */
    border: 1px solid black;
    padding: 2px;
    background-color: lightgray;
    width: 100%;
    /* display: inline-block; */
}

.ProfileDropdownOptions {
    margin-top: 30px;
}