.LocationSearchBar {
    height: 2.8em;
    width: 97%;
    text-indent: 2em;
    border-radius: 20px;
}

.LocationSearchBarLoading {
    height: 2.8em;
    width: 97%;
    border-radius: 20px;
    background-color: rgb(218, 218, 218);
    text-indent: 2em;
}