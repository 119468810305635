.Header {
    /* position: sticky;
    top: 0;
    z-index: 1; */
    position: sticky;
    top: 0;
    /* display: inline-block; */
    width: 100vw;
    border: 1px solid black;
    height: 7vh;
  }