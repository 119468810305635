.ProfileButton {
    float: right;
}

.ProfileButtonContainer {
    position: relative;
    margin: 1vh 1vh 0 0;
    float: right;
    /* background-color: white; */
    /* display: inline-block; */
}